








import Vue from 'vue'
import { mapState } from 'vuex'
import { ErrorMessage } from '~/models/dealer/site/error/types'
import { DEALER_SITE_ERROR_NS } from '~/store/modules/shared/dealers/site/error/state'

export default Vue.extend({
  computed: {
    ...mapState(DEALER_SITE_ERROR_NS, ['type']),
    message(): ErrorMessage | undefined {
      switch (this.type) {
        case '404':
          return {
            title: this.$t('page not found'),
            body: this.$t("we couldn't find the page you're looking for.")
          }
        default:
          return undefined
      }
    }
  }
})
