














import Vue from 'vue'
import { Route } from 'vue-router'
import { mapGetters, mapActions, mapState } from 'vuex'
import PageErrorMessage from '~/components/car/dealers/site/error/PageErrorMessage.vue'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { DEALER_SITE_ERROR_NS } from '~/store/modules/shared/dealers/site/error/state'
import { mapDeps } from '~/plugins/dependency-container/utils'
import DealerSiteComponentService from '~/services/dealers/site/DealerSiteComponentService'
import { getNamespacedStore } from '~/utils/store'

export default Vue.extend({
  components: {
    PageErrorMessage,
    'custom-page': () =>
      import('~/pages/car/dealers/site/pages/custom-page.vue'),
    search: () => import('~/pages/car/dealers/site/pages/search.vue'),
    contact: () => import('~/pages/car/dealers/site/pages/contact.vue'),
    classified: () => import('~/pages/car/dealers/site/classified.vue')
  },
  async asyncData(ctx) {
    if (process.server) {
      // for some reason, only in dsites, asyncData runs twice
      const dsStore = getNamespacedStore(ctx.store, DEALER_SITE_NS)
      await dsStore.dispatch('loadSite', ctx)

      const { route, redirect } = ctx

      return dsStore.dispatch(`loadPageByPath`, {
        to: route,
        redirect
      })
    }
  },
  computed: {
    ...mapState(DEALER_SITE_NS, ['indexPageType']),
    ...mapGetters(DEALER_SITE_NS, ['activePage', 'shouldRenderIndexPage']),
    ...mapGetters(DEALER_SITE_ERROR_NS, ['errorExists']),
    ...mapDeps({ dealerSiteComponentService: DealerSiteComponentService })
  },
  watch: {
    async $route(to: Route, from: Route) {
      await this.loadPageByPath({ to, from })
    }
  },
  methods: {
    ...mapActions(DEALER_SITE_NS, ['loadPageByPath'])
  }
})
